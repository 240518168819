<template>
  <fragment>
    <base-title v-model="search">Meine Orte</base-title>
    <v-card>
      <v-tooltip bottom open-delay="500" v-if="$can('create', 'Venue')">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark small absolute top right fab @click="createVenue" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Einen neuen Ort hinzufügen</span>
      </v-tooltip>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        item-key="id"
        virtual-rows
        sort-by="name"
      >
        <template v-slot:no-data>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Orte vorhanden</v-card-title>
            <v-card-text>Erstelle deinen ersten Ort</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Venue')">
              <v-btn color="primary" @click="createVenue">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:no-results>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Orte gefunden</v-card-title>
            <v-card-text>Mit dem Begriff "{{ search }}" wurden keine Orte gefunden.</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Venue')">
              <v-btn color="primary" @click="createVenue">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:item.name="{ item }">
          <div :class="$vuetify.breakpoint.xs ? null : 'd-flex'">
            <div class="align-self-center">{{ item.name }}</div>
            <v-chip v-if="item.defaultVenue" small class="ml-4 flex-shrink-0">Standard</v-chip>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div :class="$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'">
            <base-mobile-button icon="mdi-delete" tooltip="Löschen" v-if="$can('delete', 'Venue') && !item.defaultVenue" @click="deleteVenue(item)">
              <span>Löschen</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-pencil" tooltip="Bearbeiten" v-if="$can('update', 'Venue')" @click="updateVenue(item)">
              <span>Bearbeiten</span>
            </base-mobile-button>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </fragment>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Adresse', value: 'address' },
      { text: 'PLZ', value: 'zip' },
      { text: 'Ort', value: 'city' },
      { value: 'action', align: 'right', sortable: false },
    ],
  }),

  computed: {
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-venue-items'), 10) || 10;
      },
      set(value) {
        localStorage.setItem('table-venue-items', value);
      },
    },
  },

  methods: {
    createVenue() {
      this.$store.commit('venue/dialog/form', true);
    },
    updateVenue(venue) {
      this.$store.dispatch('venue/load', venue.id).then(() => {
        this.$store.commit('venue/sync');
        this.$store.commit('venue/dialog/form', true);
      });
    },
    deleteVenue(venue) {
      this.$store.commit('venue/dialog/delete', {
        id: venue.id,
        path: venue.path,
        name: venue.name,
        address: venue.address,
        zip: venue.zip,
        city: venue.city,
      });
    },
  },
};
</script>
