<template>
  <v-dialog v-model="show" max-width="900" @input="handleDialog">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-toolbar dark>
            <v-toolbar-title>Veranstaltungsort {{ venue ? "bearbeiten" : "erstellen" }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert border="left" colored-border type="info" dense color="primary"
              >Bitte gib deine Postadresse an. In deinem Konto kannst du sie als Veranstaltungsort auswählen, weitere Adressen ergänzen und wirst besser gefunden.</v-alert
            >
            <v-row>
              <v-col cols="12" md>
                <base-alert v-if="error">{{ error.message }}</base-alert>
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                  <v-text-field label="Name" v-model="name" :error-messages="errors" />
                </ValidationProvider>
                <ValidationProvider name="Adresse" rules="required" v-slot="{ errors }">
                  <v-text-field label="Adresse" v-model="address" @input="searchAddress" :error-messages="errors" />
                </ValidationProvider>
                <v-row no-gutters>
                  <v-col cols="4" class="pr-3">
                    <ValidationProvider name="PLZ" rules="required|numeric" v-slot="{ errors }">
                      <v-text-field label="PLZ" v-model="zip" @input="searchAddress" :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="8" class="pl-3">
                    <ValidationProvider name="Ort" rules="required" v-slot="{ errors }">
                      <v-text-field label="Ort" v-model="city" @input="searchAddress" :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <!-- <v-switch v-model="defaultVenue" label="Standard Veranstaltungsort" hide-details /> -->
              </v-col>
              <v-col cols="12" md>
                <gmap-map :center="googleMapsCenter" :zoom="15" :options="googleMapsOptions" style="width: 100%; height: 400px">
                  <gmap-marker v-if="geolocation" :position="geolocation" draggable @dragend="changeGoogleMapsPosition"></gmap-marker>
                </gmap-map>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">{{ venue ? "Speichern" : "Hinzufügen" }}</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { geocode } from '@/helpers/geocode';

const { mapFields } = createHelpers({
  getterType: 'venue/field',
  mutationType: 'venue/updateField',
});

export default {
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    timer: null,
    googleMapsOptions: {
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
  }),

  computed: {
    ...mapGetters({
      dialog: 'venue/dialog/form',
      venue: 'venue/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['name', 'address', 'zip', 'city', 'defaultVenue', 'geolocation']),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    googleMapsCenter() {
      if (this.geolocation) {
        return this.geolocation;
      }
      return { lat: 47.3747673, lng: 8.5339428 };
    },
  },

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (value === false && oldValue === true) {
          this.onClose();
        }
      },
    },
  },

  methods: {
    onSubmit() {
      const data = {
        name: this.name,
        address: this.address,
        zip: this.zip,
        city: this.city,
        defaultVenue: this.defaultVenue,
        geolocation: this.geolocation,
      };

      if (this.venue) {
        this.$store.dispatch('venue/update', data);
      } else {
        this.$store.dispatch('venue/create', data);
      }
    },
    onClose() {
      this.$store.commit('venue/dialog/form', false);
      this.$store.commit('venue/reset');
      this.$store.commit('error/set', null);
      this.$refs.form.reset();
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
    changeGoogleMapsPosition(event) {
      this.geolocation = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    },
    searchAddress() {
      if (this.address && this.zip && this.city) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          this.geolocation = await geocode(this.address, this.zip, this.city);
        }, 800);
      }
    },
  },
};
</script>
